import React, { createContext } from "react";
import { IDictionary } from "../types";

interface IPageData {
  pagePath?: string;
  settings?: any;
  story?: any;
}
interface PageContextProps {
  data?: IPageData;
  set?: React.Dispatch<React.SetStateAction<IPageData>>;
}

const defaultContextValue: PageContextProps = {
  data: {},
  set: () => null,
};

const PageContext = createContext(defaultContextValue);

class PageContextProvider extends React.Component {
  constructor(props: PageContextProps) {
    super(props);

    this.setData = this.setData.bind(this);
    this.state = {
      data: props.data || {},
      set: this.setData,
    };
  }

  setData(newData: IDictionary<string | {}>) {
    this.setState({ data: newData });
  }

  render() {
    return (
      <PageContext.Provider value={this.state}>
        {this.props.children}
      </PageContext.Provider>
    );
  }
}

export { PageContext as default, PageContextProvider };
