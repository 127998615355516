import React, { createContext } from "react";
import Cookies from "js-cookie";

export interface ICookieData {
  cookieClose?: boolean;
  gateClose?: boolean;
  utm_medium?: string;
  utm_content?: string;
  utm_campaign?: string;
  utm_source?: string;
  utm_term?: string;
}

export interface CookieContextProps {
  data?: ICookieData;
  set?: React.Dispatch<React.SetStateAction<ICookieData>>;
  getUtms?: {};
}

const defaultContextValue: CookieContextProps = {
  data: {},
  set: () => null,
  getUtms: {},
};

const CookieContext = createContext(defaultContextValue);

class CookieContextProvider extends React.Component {
  state = {
    data: {},
  };

  currHost() {
    return document.location.hostname.endsWith("hultef.com")
      ? ".hultef.com"
      : document.location.hostname;
  }

  expireDate() {
    const date = new Date();
    date.setDate(date.getDate() + 90);

    return date;
  }

  setCookie(key: string, value: string) {
    Cookies.set(key, value, {
      expires: this.expireDate(),
      domain: this.currHost(),
    });
  }

  getUtms() {
    return {
      utm_campaign: Cookies.get("utm_campaign"),
      utm_content: Cookies.get("utm_content"),
      utm_medium: Cookies.get("utm_medium"),
      utm_source: Cookies.get("utm_source"),
      utm_term: Cookies.get("utm_term"),
    };
  }

  setUtms(cookies: any, params: { [key: string]: string }) {
    let utm_medium = cookies["utm_medium"];
    if (params["utm_medium"]) {
      this.setCookie("utm_medium", params["utm_medium"]);
      utm_medium = params["utm_medium"];
      // this.setData({ utm_medium: params["utm_medium"] });
    }

    let utm_content = cookies["utm_content"];
    if (params["utm_content"]) {
      this.setCookie("utm_content", params["utm_content"]);
      utm_content = params["utm_content"];
      // this.setData({ utm_content: params["utm_content"] });
    }

    let utm_campaign = cookies["utm_campaign"];
    if (params["utm_campaign"]) {
      this.setCookie("utm_campaign", params["utm_campaign"]);
      utm_campaign = params["utm_campaign"];
    }

    let utm_source = cookies["utm_source"];
    if (params["utm_source"]) {
      this.setCookie("utm_source", params["utm_source"]);
      utm_source = params["utm_source"];
      // this.setData({ utm_source: params["utm_source"] });
    }

    let utm_term = cookies["utm_term"];
    if (params["utm_term"]) {
      this.setCookie("utm_term", params["utm_term"]);
      utm_term = params["utm_term"];
      // this.setData({ utm_term: params["utm_term"] });
    }

    return { utm_campaign, utm_content, utm_medium, utm_source, utm_term };
  }

  setData = (newData: React.SetStateAction<ICookieData>) => {
    let currData = this.state.data;
    Object.assign(currData, newData);
    this.setState({ data: currData }, () => {
      localStorage.setItem("CookieData", JSON.stringify(currData));
    });
  };

  componentDidMount() {
    // Getting data value from localStorage!
    const initialState =
      JSON.parse(localStorage.getItem("CookieData") as string) || {};

    let utms = {};
    if (typeof document != "undefined") {
      // get cookies
      const cookies = this.getUtms();
      // get qs
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams?.entries()) || {};
      utms = this.setUtms(cookies, params);
    }

    const union = { ...initialState, ...utms };
    this.setState({ data: union });
  }

  render() {
    return (
      <CookieContext.Provider
        value={{
          data: this.state.data,
          set: this.setData,
          getUtms: this.getUtms(),
        }}
      >
        {this.props.children}
      </CookieContext.Provider>
    );
  }
}

export { CookieContext as default, CookieContextProvider };
